@font-face {
  font-family: "Baloo";
  src: url("src/assets/fonts/Baloo/Baloo.woff2") format("woff2"),
    url("src/assets/fonts/Baloo/Baloo.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("src/assets/fonts/Montserrat/Montserrat-Medium.woff2") format("woff2"),
    url("src/assets/fonts/Montserrat/Montserrat-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("src/assets/fonts/Montserrat/Montserrat-SemiBold.woff2") format("woff2"),
    url("src/assets/fonts/Montserrat/Montserrat-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("src/assets/fonts/Montserrat/Montserrat-Bold.woff2") format("woff2"),
    url("src/assets/fonts/Montserrat/Montserrat-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
